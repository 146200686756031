import React, { useEffect, useState,useRef } from 'react'
import avatar from '../../asserts/img/avatars/04.jpg'
import { Link,useLocation } from 'react-router-dom';
import { logOut } from '../../features/auth/authSlice';
import { useDispatch } from 'react-redux';
import { useGetprofileMutation } from '../../features/auth/authApiSlice';
import { useGetWishlistMutation,useBookingListMutation,useBookingListHostMutation, } from '../../features/property/propertySlice';
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import { setWishlist} from '../../features/slice';
import avatar1 from '../../asserts/img/avatars/14.png'
import { useChangeToHostMutation } from '../../features/auth/authApiSlice';



function Sidebar() {
	const modalRef = useRef(null)
    const location = useLocation();
	const [showmodal,setShowModal]=useState(false);

    const isProfileActive=location.pathname==="/dashboard/personal-info"
    const isRecentBookingActive=location.pathname==="/dashboard/recent-booking"
    const isPendingBookingActive=location.pathname==="/dashboard/my-properties/approvals"
    const isPasswordSecurityActive=location.pathname==="/dashboard/password-security"
	const isMyPropertiesActive=location.pathname==="/dashboard/my-properties"
	const isWishlistActive=location.pathname==="/dashboard/wishlist"
	const isPaymentHistoryActive=location.pathname==="/dashboard/payment-history"
	const isPayOutHistoryActive=location.pathname==="/dashboard/payout-history"

	const isrefundRequestActive=location.pathname==="/dashboard/refund-request"
	const isReviewsActive=location.pathname==="/dashboard/reviews"
	const isNotificationsActive=location.pathname==="/dashboard/notifications"
	const [collapse,setCollapse]=useState(false)
	const [countwishlist,setCountWishlist]=useState(0)
	const [countrecentbooking,setCoutRecentBooking]=useState(0)
	const [countpendingbooking,setCountPendingBooking]=useState(0)

	const dispatch=useDispatch()
	const [getwishlist,{isLoading:isLoadingWishlist}]=useGetWishlistMutation()
	const [updateuser,{isLoading:isLoadingUpdate}]=useChangeToHostMutation()
	const [getrecentbooking,{isLoading:loadingrecent}]=useBookingListMutation()
	const [getpendingbooking,{isLoading:loadingpending}]=useBookingListHostMutation()
	const [getprofile,{isLoading}]=useGetprofileMutation()
	const [success,setSuccess]=useState(false)
	const [userData,setUserData]=useState({
		"first_name":"",
		"last_name":"",
		"email":"",
		"phone_number":"",
		'role':"",

	})



	const handleLogout=()=>{
		dispatch(logOut())
		
		
	  
	  }
	
	  const handlegetwishlist= async ()=>{
		try{
			const response = await getwishlist();
			setCountWishlist(response.data.length)
		setWishlist(response.data.length);
			console.log('response',response)
			
	
		}
		catch(e){
			console.log(e)
		}
		
	
	
	
	
	}

	const handlegetrecentbooking= async ()=>{
		try{
			const response = await getrecentbooking();
			setCoutRecentBooking(response.data.count)
		
			console.log('recent',response)
			
	
		}
		catch(e){
			console.log(e)
		}
		
	
	
	
	
	}
	  

	const handlegetpendingBooking= async ()=>{
		try{
			const response = await getpendingbooking();
			setCountPendingBooking(response.data.count)
		
			console.log('pending',response)
			
	
		}
		catch(e){
			console.log(e)
		}
		
	
	
	
	
	}
	  
	  const hangleGetProfile=async()=>{
		try{
			const profiledata=await getprofile()
			setUserData({
				...userData,
				"first_name":profiledata.data.first_name,
				"last_name":profiledata.data.last_name,
				'email':profiledata.data.email,
				'phone_number':profiledata.data.phone_number,
				'role':profiledata.data.role,
				'profile':profiledata.data.profile
			})
		}
		catch(e){
			console.log(e)
		}
		
	  }

useEffect(()=>{
hangleGetProfile()
handlegetwishlist()
handlegetrecentbooking()
handlegetpendingBooking()

},[])






const handleUpdateUser = async()=>{
	try{

		const response = await updateuser({}).unwrap();
		console.log(response)
		setSuccess(true)
		setTimeout(() => {
			setShowModal(false);
			setSuccess(false);
			window.location.reload();
		  }, 3000);

		
		

	}
	catch(e){
		console.log(e)
	}
}


useEffect(() => {
	function handleClickOutside(event) {
		

		if (modalRef.current && !modalRef.current.contains(event.target)) {
		  setShowModal(false)
	  }
	}

	document.addEventListener('mousedown', handleClickOutside);
	return () => {
		document.removeEventListener('mousedown', handleClickOutside);
	};

}, []); 


  return (
	<>
		<div className={`backdrop  ${showmodal?"show":""} `}>
      <div  class="modal fade show open-modal" id="modalDefault" tabindex="-1" style={{display: 'block'}} aria-modal="true" role="dialog">
            <div ref={modalRef} class="modal-dialog" role="document">
              <div class="modal-content">
                <div class="modal-header">
                  <h4 class="modal-title">Switching to a host account </h4>
                  <button  onClick={()=>{setShowModal(!showmodal)}} class="btn-close" type="button" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
				{
					success?
					<div class="alert alert-success" role="alert">
  your account is now a host account


</div>
					:""
				}
                  <p>
					By switching to a host account, you will gain the ability to list your properties for rent on our platform. This feature allows you to manage your properties and connect with potential renters effortlessly.
				  </p>
                </div>
                <div class="modal-footer">
                  <button class="btn btn-secondary btn-sm" type="button" data-bs-dismiss="modal "  onClick={()=>{setShowModal(!showmodal)}}>Cancel</button>

                  {
                    isLoadingUpdate?
                    <button  class="btn btn-primary btn-shadow btn-sm" type="button">
                    <span class="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>Switching...
                      </button>

                    :
                    <button class="btn btn-primary btn-shadow btn-sm" onClick={handleUpdateUser} type="button">Switch</button>

                  }
                </div>
              </div>
            </div>
          </div>
          </div>
	
    <aside className="col-lg-4 col-md-5 pe-xl-4 mb-5">
              
              <div className="card card-body border-0 shadow-sm pb-1 me-lg-1">
                <div className="d-flex d-md-block d-lg-flex align-items-start pt-lg-2 mb-4">
				<span  style={{width:"50px",height:"60px"}} className="d-inline-block py-1" data-bs-toggle="dropdown">
				{userData.profile ?      
				   <img className="rounded-circle" src={userData.profile}  style={{width:'100%',height:'100%',objectFit:"cover"}} alt="User"/>
:        <img className="rounded-circle" src={avatar1} width="40" alt="User"/>
}
</span>
				
                  <div className="pt-md-2 pt-lg-0 ps-3 ps-md-0 ps-lg-3">
                    <h2 className="fs-lg mb-0">

						{
						isLoading? <Skeleton  style={{width:"100px"}}/>
						:userData?`${userData.first_name } ${userData.last_name}` :""
						}  
						
					</h2>
					{isLoading?"":userData.role==="user"?"":
					<span className="star-rating"><i className="star-rating-icon fi-star-filled "></i><i className="star-rating-icon fi-star-filled "></i><i className="star-rating-icon fi-star-filled "></i><i className="star-rating-icon fi-star-filled "></i><i className="star-rating-icon fi-star-filled"></i></span>

}
         
                    <ul className="list-unstyled fs-sm mt-3 mb-0">
                      <li><a className="nav-link fw-normal p-0 " ><i className="fi-phone opacity-60 me-2"></i>
					  {isLoading? <Skeleton  style={{width:"100px"}}/>:userData?userData.phone_number:"" }

					  </a></li>
                      <li><a className="nav-link fw-normal p-0" href="mailto:bigdanny@email.com"><i className="fi-mail opacity-60 me-2"></i>						{isLoading? <Skeleton  style={{width:"100px"}}/>:userData?userData.email:""}
</a></li>
{isLoading?"":userData.role==="user"?"":
<li>
						<lable className="nav-link fw-normal p-0">
							<i className="fi-cash opacity-60 me-2"> </i>
							GHS 0.00
						</lable>
					</li>
}
                     
                    </ul>
                  </div>
                </div>

				

				{isLoading?"":userData.role==="user"?
				<Link to="/properties" className="btn btn-primary btn-lg w-100 mb-3">
				<i className="fi-search me-2"></i>
				Find Accomodation
			</Link>
                :
				<Link to="/dashboard/my-properties/add" className="btn btn-primary btn-lg w-100 mb-3">
				<i className="fi-plus me-2"></i>
				Add Property
			</Link>
                
}



{isLoading?"":userData.role==="user"?
				<button  onClick={()=>{setShowModal(true)}}  className="btn btn-primary btn-lg w-100 mb-3">
				<i className="fi-real-estate-house me-2"></i>
				Become a host 
			</button>
                :
				""
                
}
       
              

                <a onClick={()=>{setCollapse(!collapse)}} className={`btn btn-outline-secondary d-block d-md-none w-100 mb-3 ${collapse?"collapsed":""} `} href="#account-nav" data-bs-toggle="collapse"><i className="fi-align-justify me-2"></i>Menu</a>
                <div className={`collapse d-md-block mt-3  ${ collapse?"show":""} `} id="account-nav">
			<div className="card-nav">
				<Link to="/dashboard/personal-info" className={`card-nav-link  ${isProfileActive?'active':''} d-flex align-items-center `}  >
					<i className="fi-user opacity-60 me-2"></i>
					<span style={{width:"80%"}} className='d-flex justify-content-between  align-items-center'>
					Personal Info
					<span class="badge bg-primary ms-1"></span>
					</span>
					
	
				</Link>
				<Link to="/dashboard/recent-booking" className={`card-nav-link d-flex justify-content-between ${isRecentBookingActive?'active':''} `} >
				<div>
				<i className="fi-bookmark opacity-60 me-2"></i>
				Recent Bookings
				</div>
				
					<span class="badge rounded-pill bg-info">{countrecentbooking}</span>

				</Link>
									<Link to="/dashboard/my-properties/approvals" className={`card-nav-link d-flex justify-content-between  ${isPendingBookingActive?'active':''} `}>
									<div>
						<i className="fi-bookmark opacity-60 me-2"></i>
						Pending Bookings
						</div>

						<span class="badge rounded-pill bg-info">{countpendingbooking}</span>

					</Link>
				
				<Link to="/dashboard/password-security"  className={`card-nav-link ${isPasswordSecurityActive?'active':''} `}>
					<i className="fi-lock opacity-60 me-2"></i>
					Password &amp; Security
				</Link>
							
				{isLoading?"":userData.role==="user"?"":
				<Link to="/dashboard/my-properties" className={`card-nav-link ${isMyPropertiesActive?'active':''} `}>
						<i className="fi-home opacity-60 me-2"></i>
						My Properties
					</Link>
                
}
              
       
					
								<Link to='/dashboard/wishlist' className={`card-nav-link d-flex justify-content-between ${isWishlistActive?'active':''}`}  >
					<div>
					<i className="fi-heart opacity-60 me-2"></i>
					Wishlist
					</div>
					


					<span class="badge rounded-pill bg-info">{countwishlist}</span>


					
				</Link>


				{isLoading?"":userData.role==="user"?"":
				
			
				<Link to='/dashboard/payout-history' className={`card-nav-link ${isPayOutHistoryActive?'active':''}`}  >
						<i className="fi-cash opacity-60 me-2"></i>
						Payout History
					</Link>
			
}



				
			
				<Link to='/dashboard/payment-history' className={`card-nav-link ${isPaymentHistoryActive?'active':''}`}  >
						<i className="fi-cash opacity-60 me-2"></i>
						Payment History
					</Link>
			



					{isLoading?"":userData.role==="user"?"":
				
					<Link to="/dashboard/refund-request" className={`card-nav-link ${isrefundRequestActive?'active':''}`}  >
						<i className="fi-briefcase opacity-60 me-2"></i>
						Refunds <span className="badge bg-info">0 pending request's</span>
					</Link>
                
}


					{isLoading?"":userData.role==="user"?"":
					<Link   className={`card-nav-link ${isReviewsActive?'active':''}`}    to="/dashboard/reviews" >
					<i className="fi-star opacity-60 me-2"></i>
					Reviews
				</Link>
                
}
           
					
				<Link to="/dashboard/notifications" className={`card-nav-link ${isNotificationsActive?'active':''}`}  >
					<i className="fi-bell opacity-60 me-2"></i>
					Notifications
				</Link>
				<Link to='/help' className="card-nav-link " >
					<i className="fi-help opacity-60 me-2"></i>
					Help
				</Link>
				<span onClick={handleLogout} style={{cursor:'pointer'}} className="card-nav-link">
					<i className="fi-logout opacity-60 me-2"></i>
					Sign Out
				</span>
			</div>
		</div>
              </div>
            </aside>
			</>
  )
}

export default Sidebar