import { apiSlice } from "../../app/api/apiSlice";


export const eventApiSlice=apiSlice.injectEndpoints({


    endpoints:builder=>({
        getEvent:builder.mutation({
            query:(param)=>({
                url:'admin/enquiry/event/',
                method: 'GET',
                params:param
                
            })
        }), 

        getEventDetail:builder.mutation({
            query:(id)=>({
                url:   `admin/enquiry/event/${id}/`,
                method: 'GET',
                
            })
        }), 
    })

   

})


export const {

    useGetEventMutation,
    useGetEventDetailMutation,
}=eventApiSlice