import React from 'react'
import Navbar from '../components/navbar'
import Hero from '../components/landingcomponents/hero'
import Categoriescarousel from '../components/landingcomponents/categoriescarousel'
import AvailableListing from '../components/landingcomponents/availableListing'
import TopListing from '../components/landingcomponents/topListing'
import "../asserts/css/theme.min.css";
import InterestingPlaces from '../components/landingcomponents/interestingPlaces'
import Findavailable from '../components/landingcomponents/findavailable'
import PageLoading from '../components/landingcomponents/pageLoader';
import { Link } from 'react-router-dom'
import { selectCurrentUser,selectCurrentToken } from '../features/auth/authSlice'
import { useSelector } from 'react-redux'
import CitiesFilter from '../components/landingcomponents/citiesFilter'
import CitiesCarousel from '../components/landingcomponents/citiesCarousel'
import Layout2 from '../components/landingcomponents/layout2'



function Accra_home() {
const user=useSelector(selectCurrentUser)
const token = useSelector(selectCurrentToken)


  return (
    <main >
    
    
      <Hero/>
      <Categoriescarousel/>
      <AvailableListing/>
    
      <CitiesFilter/>
     
      <InterestingPlaces/>
      <Findavailable/>
      <PageLoading/>
      </main>
  )
}

export default Accra_home