
import React, { useEffect, useState ,useCallback,useRef} from 'react'
import PageLoading from '../components/landingcomponents/pageLoader';

import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import Autocomplete from "react-google-autocomplete";
import debounce from 'lodash.debounce';
import { useLocation } from 'react-router-dom';
import { useGetEventMutation } from '../features/property/eventSlice';
import { useNavigate } from 'react-router-dom';


function Events() {


  const [count, setCount] = useState()
  const addressref=useRef()
  const [address,setAddress]=useState(localStorage.getItem('address'))
  const [sidebar,setSidebar]=useState(false)
  const [events,setEvents]=useState()
  const navigate=useNavigate()
  
const [getevents,{isLoading}]=useGetEventMutation()
const [title,setTitle]=useState('')
const titleref=useRef(null)


const [nextEventList,setNextEvent]=useState()
const [previousEventList,setPreviousEvent]=useState()
const [page,setPage]=useState(1)
const [pageCount,setPageCount]=useState()

const handleGetEvents=async(params,page)=>{

const param={
  title__icontains:params,
  pages:page
}
console.log(param)
const response = await getevents(param).unwrap()
console.log(response.data)
setCount(response.count)
setEvents(response.results)
console.log("next" ,response.next)
setNextEvent(response.next)
setPreviousEvent(response.previous)
setPageCount(0)
setPageCount(Math.ceil(response.count / 10))
console.log("page count: " ,pageCount)


}


const handlePagination=(page)=>{
  setPage(page);
  handleGetEvents(title,page);
}



const hangleNavigate=(id,slug)=>{
    localStorage.setItem('event_id',id)
    navigate(`/events/detail/${slug} `)
}



 


useEffect(()=>{
handleGetEvents(title,page)
},[])








const handleTitleChange = () => {
  setTitle(titleref.current.value);

  debouncedCheckIfDoneTyping(titleref.current.value);
};



const checkIfDoneTyping = () => { 
  setTitle(titleref.current.value);
  
 
handleGetEvents(titleref.current.value);
};

 
  
const debouncedCheckIfDoneTyping = useCallback(debounce(checkIfDoneTyping, 3000), []);


  return (
    <div className="container-fluid mt-5 pt-5 p-0">
      <PageLoading />


      <div className="row g-0 mt-n3">
        <aside className="col-lg-4 col-xl-3 border-top-lg border-end-lg shadow-sm px-3 px-xl-4 px-xxl-5 pt-lg-2">
        <div className={`offcanvas offcanvas-start offcanvas-collapse  ${sidebar?"show":""}`} id="filters-sidebar">
            <div className="offcanvas-header d-flex d-lg-none align-items-center">
              <h2 className="h5 mb-0">Filters</h2>
              <button onClick={()=>{setSidebar(false)}} className="btn-close" type="button" data-bs-dismiss="offcanvas"></button>
            </div>


            <div class="offcanvas-header d-block border-bottom pt-0 pt-lg-4 px-lg-0">
					<form class="form-group mb-lg-2 rounded-pill">
						<div class="input-group"><span class="input-group-text text-muted">
								<i class="fi-search"></i>
							</span>
							<input ref={titleref} onChange={handleTitleChange} class="form-control" type="text" placeholder="Search..." />
						</div>
						
					</form>
				</div>

            <div className="offcanvas-body ">
             
             
              
           
              <div className="border-top py-4">
                <button className="btn btn-outline-primary" type="button"><i className="fi-rotate-right me-2"></i>Reset filters</button>
              </div>
            </div>
          </div>
          {
            sidebar?
            <div class={`offcanvas-backdrop fade  show`} ></div>:""

          }
         
        </aside>
        <div class="col-lg-8 col-xl-9 position-relative overflow-hidden pb-5 pt-4 px-3 px-xl-4 px-xxl-5">

          <div class="map-popup invisible" id="map">
            <button class="btn btn-icon btn-light btn-sm shadow-sm rounded-circle" type="button" data-bs-toggle-class="invisible" data-bs-target="#map"><i class="fi-x fs-xs"></i></button>
            <div class="interactive-map" data-map-options-json="json/map-options-real-estate-rent.json"></div>
          </div>

          <nav class="mb-3 pt-md-2" aria-label="Breadcrumb">
            <ol class="breadcrumb">
              <li class="breadcrumb-item"><a href="real-estate-home-v1.html">Home</a></li>
              <li class="breadcrumb-item active" aria-current="page">Experiences </li>
            </ol>
          </nav>

          <div class="d-sm-flex align-items-center justify-content-between pb-3 pb-sm-4">
            <h1 class="h2 mb-sm-0">Experiences </h1>
          </div>

          <div class="d-flex flex-sm-row flex-column align-items-sm-center align-items-stretch my-2">
            <div class="d-flex align-items-center flex-shrink-0">
              <label class="fs-sm me-2 pe-1 text-nowrap" for="sortby"><i class="fi-arrows-sort text-muted mt-n1 me-2"></i>Sort by:</label>
              <select class="form-select form-select-sm" id="sortby">
                <option>Newest</option>
                <option>Popularity</option>
                <option>Low - High Price</option>
                <option>High - Low Price</option>
                <option>High rating</option>
                <option>Average Rating</option>
              </select>
            </div>
            <hr class="d-none d-sm-block w-100 mx-4" />
            <div class="d-none d-sm-flex align-items-center flex-shrink-0 text-muted"><i class="fi-check-circle me-2"></i><span class="fs-sm mt-n1">{count&&count} results</span></div>
          </div>

          <div class="row g-4 py-4 ">



            {isLoading ?

              <>
                <div className="col-sm-6 col-xl-4">

                  <div className="card shadow-sm card-hover border-0 h-100">
                    <div className="card-img-top card-img-hover"><div className="img-overlay" ></div>
                      <div className="position-absolute start-0 top-0 pt-3 ps-3"></div>


                      <Skeleton style={{ width: "400px", height: "200px" }} />

                    </div>
                    <div className="card-body position-relative pb-3">
                      <h4 className="mb-1 fs-xs fw-normal text-uppercase text-primary"><Skeleton style={{ width: "80px", height: "7px" }} /></h4>
                      <h3 className="h6 mb-2 fs-base"><div className="nav-link stretched-link" ><Skeleton style={{ width: "150px", height: "15px" }} /></div></h3>
                      <p className="mb-2 fs-sm text-muted"><Skeleton style={{ width: "100px", height: "10px" }} /></p>
                      <div className="fw-bold"><Skeleton style={{ width: "100px", height: "20px" }} /></div>
                    </div>
                    <div className="card-footer d-flex align-items-center justify-content-center mx-3 pt-3 text-nowrap"></div>
                  </div>
                </div>


                <div className="col-sm-6 col-xl-4">

                  <div className="card shadow-sm card-hover border-0 h-100">
                    <div className="card-img-top card-img-hover"><div className="img-overlay" ></div>
                      <div className="position-absolute start-0 top-0 pt-3 ps-3"></div>


                      <Skeleton style={{ width: "400px", height: "200px" }} />

                    </div>
                    <div className="card-body position-relative pb-3">
                      <h4 className="mb-1 fs-xs fw-normal text-uppercase text-primary"><Skeleton style={{ width: "80px", height: "7px" }} /></h4>
                      <h3 className="h6 mb-2 fs-base"><div className="nav-link stretched-link" href="real-estate-single-v1.html"><Skeleton style={{ width: "150px", height: "15px" }} /></div></h3>
                      <p className="mb-2 fs-sm text-muted"><Skeleton style={{ width: "100px", height: "10px" }} /></p>
                      <div className="fw-bold"><Skeleton style={{ width: "100px", height: "20px" }} /></div>
                    </div>
                    <div className="card-footer d-flex align-items-center justify-content-center mx-3 pt-3 text-nowrap"></div>
                  </div>
                </div>


                <div className="col-sm-6 col-xl-4">

                  <div className="card shadow-sm card-hover border-0 h-100">
                    <div className="card-img-top card-img-hover"><div className="img-overlay" href="real-estate-single-v1.html"></div>
                      <div className="position-absolute start-0 top-0 pt-3 ps-3"></div>


                      <Skeleton style={{ width: "400px", height: "200px" }} />

                    </div>
                    <div className="card-body position-relative pb-3">
                      <h4 className="mb-1 fs-xs fw-normal text-uppercase text-primary"><Skeleton style={{ width: "80px", height: "7px" }} /></h4>
                      <h3 className="h6 mb-2 fs-base"><div className="nav-link stretched-link" href="real-estate-single-v1.html"><Skeleton style={{ width: "150px", height: "15px" }} /></div></h3>
                      <p className="mb-2 fs-sm text-muted"><Skeleton style={{ width: "100px", height: "10px" }} /></p>
                      <div className="fw-bold"><Skeleton style={{ width: "100px", height: "20px" }} /></div>
                    </div>
                    <div className="card-footer d-flex align-items-center justify-content-center mx-3 pt-3 text-nowrap"></div>
                  </div>
                </div>

              </> :
              <>

               
              <div class="row row-cols-xl-3 row-cols-sm-2 row-cols-1 gy-4 gx-3 gx-xxl-4 py-4">

                        {
                            events && events.map((event,index)=>{



                                const endDate = new Date(event.end_date);
                                const  startDate = new Date(event.start_date);
                                const currentDate = new Date();
                                
                            
                                let timeframe=""
                               if(startDate>currentDate && endDate<currentDate)
                                {
                                  timeframe='Ongoing'
                                }

                                else if  (startDate>currentDate){
                                   timeframe="Not Started"
                                }
                
                                else if (endDate>currentDate) {
                                    timeframe='Ended'

                                }
                                
                                

                                return(
                                    <div key={index} class="col pb-sm-2">
						<article class="position-relative">
							<div class="position-relative mb-3">
																	<span class={`d-table badge ${event.event_type==="active"?"bg-primary":"bg-danger"}  position-absolute top-0 end-0 m-3 zindex-5`}>{ event.event_type}</span>
															</div>
							<div class="position-relative mb-3">
								
								<img class="rounded-3" style={{height:'200px',objectFit:'cover', width: '100%'}} src={event.image} alt="Article img"/>
							</div>
							<h3 class="mb-2 fs-lg">
								<span  onClick={()=>{hangleNavigate(event.id,event.slug)}}  class="nav-link stretched-link" >
									{event.title}
								</span>
							</h3>
							<ul class="list-inline mb-0 fs-xs">
								
								<li class="list-inline-item pe-1">
									<i class="fi-credit-card mt-n1 me-1 fs-base text-muted align-middle"></i>
																			₵{event.price}
																	</li>
								<li class="list-inline-item pe-1">
									<i class="fi-map-pin mt-n1 me-1 fs-base text-muted align-middle"></i>
									{event.location}
								</li>
							</ul>
						</article>
					</div>
                                )
                            })
                        }		
				
				
							</div>
                










              </>}

              {
  pageCount<=1?
  ""
  :
  <nav className="mt-2 mb-4" aria-label="Reviews pagination">
              <ul className="pagination">
                <li className="page-item d-sm-none"><span className="page-link page-link-static">1 / 5</span></li>
                {
                  previousEventList?
                  <li className="page-item"><span onClick={()=>{handlePagination(page -1)}} className="page-link" href="#" aria-label="Previous"><i className="fi-chevron-left"></i></span></li>
                  :
                  ""


                }
                {
                  page-2>0 && page-2<=pageCount?
                  <li className="page-item d-none d-sm-block"><span className="page-link" href="#" onClick={()=>{handlePagination(page - 2)}}>{page-2}</span></li>
                  :""


                }
                {
                 page>0 &&previousEventList?

                  <li className="page-item d-none d-sm-block"><span onClick={()=>{handlePagination(page-1)}} className="page-link" href="#">{page-1}</span></li>
                  :
                  ""


                }
                
                {
                  page>0?
                  <li className="page-item active d-none d-sm-block" aria-current="page"><span className="page-link">{page}<span className="visually-hidden">(current)</span></span></li>
                  :''


                }

                {
                  nextEventList?
                  <li className="page-item d-none d-sm-block"><span className="page-link" onClick={()=>{handlePagination(page+ 1)}}>{page+1}</span></li>

                  :""

                }
                {
                  page+2<=pageCount?
                  <li className="page-item d-none d-sm-block"><span className="page-link" href="#" onClick={()=>{handlePagination(page + 2)}}>{page+2}</span></li>
                  :""


                }

                {
                  page+4<=pageCount?
                  <li className="page-item d-none d-sm-block">...</li>
                  
                  :""
                }
                {
                  page+4<=pageCount?
                  <li className="page-item d-none d-sm-block"><span className="page-link"  onClick={()=>{handlePagination(pageCount&&pageCount)}} href="#"> {pageCount&&pageCount}</span></li>
                  
                  :"" 
                }
                {
                  nextEventList?
                  <li className="page-item"><span  onClick={()=>{handlePagination(page + 1)}} className="page-link" href="#" aria-label="Next"><i className="fi-chevron-right"></i></span></li>
                  :
                  ""

                }
                
                
              </ul>
            </nav>
}
          </div>
          <button  onClick={()=>{setSidebar(!sidebar)}} class="btn btn-primary btn-sm w-100 rounded-0 fixed-bottom d-lg-none" type="button" data-bs-toggle="offcanvas" data-bs-target="#filters-sidebar"><i class="fi-filter me-2"></i>Filters</button>
        </div>
      </div>
    </div>
  )
}

export default Events