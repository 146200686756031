import React, { useState ,useEffect,useRef} from 'react'
import heroimg from "../../asserts/img/city-guide/home/hero-img.png"
import { useGetCategoriesMutation } from '../../features/property/propertySlice'
import { useNavigate } from 'react-router-dom'
import Autocomplete from "react-google-autocomplete";



const placesLibrary=['places']
function Hero() {


  const [data,setData]=useState({
   
      "category":"",
      "price_max":"",
      "price_min":""
  })
  const navigate=useNavigate()


  const handleChange=(event) =>{
    const {name,value}=event.target
    setData({...data,[name]:value})
    
  }

  const [location,setLocation]=useState('')
  



    const [getcategories,{isLoading}]=useGetCategoriesMutation()


    const [category,setcategory]=useState(false)
    const [categories,setCategories]=useState(false)

    const categoryref= useRef(null);
   
    const handleGetCategories=async()=>{
      try{
    
        const categories= await getcategories()
        console.log(categories)
        setCategories(null)
        setCategories(categories.data)
      
    
      }catch(e){
        console.log(e)
    
      }
    }
    
    useEffect(()=>{
      handleGetCategories()
    },[])


    const handleClickOutside = (event) => {
      if (categoryref.current && !categoryref.current.contains(event.target)) {
        setcategory(false);
      }
    };
  
    useEffect(() => {
      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, []);

    const locationref=useRef(null)

    const handleChangeCity=()=>{

      setLocation(locationref.current.value.replace("Ghana", '',).replace(",",""));
      
    }


    const handleSubmit = (e) => {
      e.preventDefault(); 
      localStorage.setItem('city',location);
      localStorage.setItem('category_name',data.category);
      localStorage.setItem('pricemax',data.price_max);
      localStorage.setItem('pricemin',data.price_min);
      console.log(data);

      setData({
        "location": "",
        "category": "",
        "price_max": "",
        "price_min": ""
      });
      navigate('/properties')
    }    

  return (
    <section className="container py-5 mt-5 mb-lg-3">
    <div className="row align-items-center mt-md-2">
      <div className="col-lg-7 order-lg-2 mb-lg-0 mb-4 pb-2 pb-lg-0">
    <img className="d-block mx-auto" src={heroimg} width="746" alt="photo"/></div>
      <div className="col-lg-5 order-lg-1 pe-lg-0">
        <h1 className="display-5 mb-4 me-lg-n5 text-lg-start text-center mb-4">Explore shortlets in <span className="d-inline-block"><a className="text-decoration-none" href="#" role="button" aria-haspopup="true" aria-expanded="false">Accra</a></span></h1>
        <p className="text-lg-start text-center mb-4 mb-lg-5 fs-lg">Find great places to stay, eat, shop, or visit from our partners and local experts.</p>
       
      </div>
      <div className="col-xl-8 col-lg-10 order-3 mt-lg-n5">
            <form onSubmit={handleSubmit} className="form-group d-block">
              <div className="row g-0 ms-sm-n2">
                <div className="col-md-8 d-sm-flex align-items-center">

                <div className="dropdown w-sm-50 border-end-sm ps-2 ps-sm-3 d-flex   align-content-center justify-content-center" >
                 <i className="fi-map-pin mt-3"></i>


<Autocomplete
  apiKey={"AIzaSyADkWwYDwUmTWehisZ_iv5V09S_xEw4yWg"}  // Replace with your actual API key

  options={{
    types: ['(cities)'],   // restrict search to addresses only
    componentRestrictions: { country: "Gh" }  // limit search to United States
  }}
  value={data.location}
  onPlaceSelected={handleChangeCity}
 
 
onChange={handleChangeCity}
ref={locationref}
  
  className='form-control '
  placeholder="city"
 


/>
                    
                    
                
                  </div>

                  
               
                
                  <hr className="d-sm-none my-2"/>
                  <div className="dropdown w-sm-50 border-end-md d-flex ps-2 ps-sm-3" data-bs-toggle="select">
                  <i className="fi-list  mt-3"></i>
                  <select 
                  
                  name="category" 
      value={data.category} 
      onChange={handleChange} 
      placeholder="Location" 
                  className=" w-100 form-control"  >
  <option disabled hidden value='' selected>

    category
  </option>
  {
    categories && categories.map((category, index) => {
      return (
        <option key={index}>{category.name}</option>
      )
    })
  }
</select>

                  </div>
                </div>
                <hr className="d-md-none mt-2"/>
                <div class="col-md-4 d-sm-flex align-items-center pt-4 pt-md-0">
				<div class="d-flex align-items-center w-100 pt-2 pb-4 py-sm-0 ps-2 ps-sm-3">
					<i class="fi-cash fs-lg text-muted me-2"></i>
					
					<input 
          name="price_max" 
      value={data.price_min} 
      onChange={handleChange} 
    
           class="form-control range-slider-value-min text-muted" type="number"  placeholder='100' />
					<div class="text-muted">—</div>
					<input
          name="price_min" 
      value={data.price_max} 
      onChange={handleChange} 
     
     class="form-control range-slider-value-max text-muted" placeholder='1000' type="number"  />
				</div>

				<button class="btn btn-icon btn-primary px-3 w-100 w-sm-auto flex-shrink-0" type="submit">
					<i class="fi-search"></i>
					<span class="d-sm-none d-inline-block ms-2">
						Search
					</span>
				</button>
			</div>
              </div>
            </form>
          </div>
    </div>
  </section>
  )
}

export default Hero