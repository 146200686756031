import { useState ,useRef} from 'react'
import signuplogo from '../asserts/img/signin-modal/adduser.svg'
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch } from "react-redux";
import { useRegisterMutation,useLoginMutation} from '../features/auth/authApiSlice';
import { setCredentials } from '../features/auth/authSlice';
import { useResendotpMutation } from '../features/auth/authApiSlice';


function SignupPage() {
     const navigate = useNavigate();
     const  [seepassword,setseepassword]=useState(false)
     const  [seeconfirmpassword,setconfirmseepassword]=useState(false)
	 const[register,{isLoading:isloadingregister}]=useRegisterMutation()
	 const[login,{isLoading:isloadinglogin}]=useLoginMutation()
	 const dispatch = useDispatch()
	 const emaildanger=useRef(null)
	 const phonedanger=useRef(null)




const countryCodes = [
  { value: "93", label: "AF +93" },
  { value: "355", label: "AL +355" },
  { value: "213", label: "DZ +213" },
  { value: "1684", label: "AS +1684" },
  { value: "376", label: "AD +376" },
  { value: "244", label: "AO +244" },
  { value: "1264", label: "AI +1264" },
  { value: "0", label: "AQ +0" },
  { value: "1268", label: "AG +1268" },
  { value: "54", label: "AR +54" },
  { value: "374", label: "AM +374" },
  { value: "297", label: "AW +297" },
  { value: "61", label: "AU +61" },
  { value: "43", label: "AT +43" },
  { value: "994", label: "AZ +994" },
  { value: "1242", label: "BS +1242" },
  { value: "973", label: "BH +973" },
  { value: "880", label: "BD +880" },
  { value: "1246", label: "BB +1246" },
  { value: "375", label: "BY +375" },
  { value: "32", label: "BE +32" },
  { value: "501", label: "BZ +501" },
  { value: "229", label: "BJ +229" },
  { value: "1441", label: "BM +1441" },
  { value: "975", label: "BT +975" },
  { value: "591", label: "BO +591" },
  { value: "387", label: "BA +387" },
  { value: "267", label: "BW +267" },
  { value: "0", label: "BV +0" },
  { value: "55", label: "BR +55" },
  { value: "246", label: "IO +246" },
  { value: "673", label: "BN +673" },
  { value: "359", label: "BG +359" },
  { value: "226", label: "BF +226" },
  { value: "257", label: "BI +257" },
  { value: "855", label: "KH +855" },
  { value: "237", label: "CM +237" },
  { value: "1", label: "CA +1" },
  { value: "238", label: "CV +238" },
  { value: "1345", label: "KY +1345" },
  { value: "236", label: "CF +236" },
  { value: "235", label: "TD +235" },
  { value: "56", label: "CL +56" },
  { value: "86", label: "CN +86" },
  { value: "61", label: "CX +61" },
  { value: "672", label: "CC +672" },
  { value: "57", label: "CO +57" },
  { value: "269", label: "KM +269" },
  { value: "242", label: "CG +242" },
  { value: "242", label: "CD +242" },
  { value: "682", label: "CK +682" },
  { value: "506", label: "CR +506" },
  { value: "225", label: "CI +225" },
  { value: "385", label: "HR +385" },
  { value: "53", label: "CU +53" },
  { value: "357", label: "CY +357" },
  { value: "420", label: "CZ +420" },
  { value: "45", label: "DK +45" },
  { value: "253", label: "DJ +253" },
  { value: "1767", label: "DM +1767" },
  { value: "1809", label: "DO +1809" },
  { value: "593", label: "EC +593" },
  { value: "20", label: "EG +20" },
  { value: "503", label: "SV +503" },
  { value: "240", label: "GQ +240" },
  { value: "291", label: "ER +291" },
  { value: "372", label: "EE +372" },
  { value: "251", label: "ET +251" },
  { value: "500", label: "FK +500" },
  { value: "298", label: "FO +298" },
  { value: "679", label: "FJ +679" },
  { value: "358", label: "FI +358" },
  { value: "33", label: "FR +33" },
  { value: "594", label: "GF +594" },
  { value: "689", label: "PF +689" },
  { value: "0", label: "TF +0" },
  { value: "241", label: "GA +241" },
  { value: "220", label: "GM +220" },
  { value: "995", label: "GE +995" },
  { value: "49", label: "DE +49" },
  { value: "233", label: "GH +233" },
  { value: "350", label: "GI +350" },
  { value: "30", label: "GR +30" },
  { value: "299", label: "GL +299" },
  { value: "1473", label: "GD +1473" },
  { value: "590", label: "GP +590" },
  { value: "1671", label: "GU +1671" },
  { value: "502", label: "GT +502" },
  { value: "224", label: "GN +224" },
  { value: "245", label: "GW +245" },
  { value: "592", label: "GY +592" },
  { value: "509", label: "HT +509" },
  { value: "0", label: "HM +0" },
  { value: "39", label: "VA +39" },
  { value: "504", label: "HN +504" },
  { value: "852", label: "HK +852" },
  { value: "36", label: "HU +36" },
  { value: "354", label: "IS +354" },
  { value: "91", label: "IN +91" },
  { value: "62", label: "ID +62" },
  { value: "98", label: "IR +98" },
  { value: "964", label: "IQ +964" },
  { value: "353", label: "IE +353" },
  { value: "972", label: "IL +972" },
  { value: "39", label: "IT +39" },
  { value: "1876", label: "JM +1876" },
  { value: "81", label: "JP +81" },
  { value: "962", label: "JO +962" },
  { value: "7", label: "KZ +7" },
  { value: "254", label: "KE +254" },
  { value: "686", label: "KI +686" },
  { value: "850", label: "KP +850" },
  { value: "82", label: "KR +82" },
  { value: "965", label: "KW +965" },
  { value: "996", label: "KG +996" },
  { value: "856", label: "LA +856" },
  { value: "371", label: "LV +371" },
  { value: "961", label: "LB +961" },
  { value: "266", label: "LS +266" },
  { value: "231", label: "LR +231" },
  { value: "218", label: "LY +218" },
  { value: "423", label: "LI +423" },
  { value: "370", label: "LT +370" },
  { value: "352", label: "LU +352" },
  { value: "853", label: "MO +853" },
  { value: "389", label: "MK +389" },
  { value: "261", label: "MG +261" },
  { value: "265", label: "MW +265" },
  { value: "60", label: "MY +60" },
  { value: "960", label: "MV +960" },
  { value: "223", label: "ML +223" },
  { value: "356", label: "MT +356" },
  { value: "692", label: "MH +692" },
  { value: "596", label: "MQ +596" },
  { value: "222", label: "MR +222" },
  { value: "230", label: "MU +230" },
  { value: "269", label: "YT +269" },
  { value: "52", label: "MX +52" },
  { value: "691", label: "FM +691" },
  { value: "373", label: "MD +373" },
  { value: "377", label: "MC +377" },
  { value: "976", label: "MN +976" },
  { value: "1664", label: "MS +1664" },
  { value: "212", label: "MA +212" },
  { value: "258", label: "MZ +258" },
  { value: "95", label: "MM +95" },
  { value: "264", label: "NA +264" },
  { value: "674", label: "NR +674" },
  { value: "977", label: "NP +977" },
  { value: "31", label: "NL +31" },
  { value: "599", label: "AN +599" },
  { value: "687", label: "NC +687" },
  { value: "64", label: "NZ +64" },
  { value: "505", label: "NI +505" },
  { value: "227", label: "NE +227" },
  { value: "234", label: "NG +234" },
  { value: "683", label: "NU +683" },
  { value: "672", label: "NF +672" },
  { value: "1670", label: "MP +1670" },
  { value: "47", label: "NO +47" },
  { value: "968", label: "OM +968" },
  { value: "92", label: "PK +92" },
  { value: "680", label: "PW +680" },
  { value: "970", label: "PS +970" },
  { value: "507", label: "PA +507" },
  { value: "675", label: "PG +675" },
  { value: "595", label: "PY +595" },
  { value: "51", label: "PE +51" },
  { value: "63", label: "PH +63" },
  { value: "0", label: "PN +0" },
  { value: "48", label: "PL +48" },
  { value: "351", label: "PT +351" },
  { value: "1787", label: "PR +1787" },
  { value: "974", label: "QA +974" },
  { value: "262", label: "RE +262" },
  { value: "40", label: "RO +40" },
  { value: "70", label: "RU +70" },
  { value: "250", label: "RW +250" },
  { value: "290", label: "SH +290" },
  { value: "1869", label: "KN +1869" },
  { value: "1758", label: "LC +1758" },
  { value: "508", label: "PM +508" },
  { value: "1784", label: "VC +1784" },
  { value: "684", label: "WS +684" },
  { value: "378", label: "SM +378" },
  { value: "239", label: "ST +239" },
  { value: "966", label: "SA +966" },
  { value: "221", label: "SN +221" },
  { value: "381", label: "CS +381" },
  { value: "248", label: "SC +248" },
  { value: "232", label: "SL +232" },
  { value: "65", label: "SG +65" },
  { value: "421", label: "SK +421" },
  { value: "386", label: "SI +386" },
  { value: "677", label: "SB +677" },
  { value: "252", label: "SO +252" },
  { value: "27", label: "ZA +27" },
  { value: "0", label: "GS +0" },
  { value: "34", label: "ES +34" },
  { value: "94", label: "LK +94" },
  { value: "249", label: "SD +249" },
  { value: "597", label: "SR +597" },
  { value: "47", label: "SJ +47" },
  { value: "268", label: "SZ +268" },
  { value: "46", label: "SE +46" },
  { value: "41", label: "CH +41" },
  { value: "963", label: "SY +963" },
  { value: "886", label: "TW +886" },
  { value: "992", label: "TJ +992" },
  { value: "255", label: "TZ +255" },
  { value: "66", label: "TH +66" },
  { value: "670", label: "TL +670" },
  { value: "228", label: "TG +228" },
  { value: "690", label: "TK +690" },
  { value: "676", label: "TO +676" },
  { value: "1868", label: "TT +1868" },
  { value: "216", label: "TN +216" },
  { value: "90", label: "TR +90" },
  { value: "7370", label: "TM +7370" },
  { value: "1649", label: "TC +1649" },
  { value: "688", label: "TV +688" },
  { value: "256", label: "UG +256" },
  { value: "380", label: "UA +380" },
  { value: "971", label: "AE +971" },
  { value: "44", label: "GB +44" },
  { value: "1", label: "US +1" },
  { value: "1", label: "UM +1" },
  { value: "598", label: "UY +598" },
  { value: "998", label: "UZ +998" },
  { value: "678", label: "VU +678" },
  { value: "58", label: "VE +58" },
  { value: "84", label: "VN +84" },
  { value: "1284", label: "VG +1284" },
  { value: "1340", label: "VI +1340" },
  { value: "681", label: "WF +681" },
  { value: "212", label: "EH +212" },
  { value: "967", label: "YE +967" },
  { value: "260", label: "ZM +260" },
  { value: "263", label: "ZW +263" },
  { value: "381", label: "RS +381" },
  { value: "0", label: "AP +0" },
  { value: "382", label: "ME +382" },
  { value: "358", label: "AX +358" },
  { value: "599", label: "BQ +599" },
  { value: "599", label: "CW +599" },
  { value: "44", label: "GG +44" },
  { value: "44", label: "IM +44" },
  { value: "44", label: "JE +44" },
  { value: "381", label: "XK +381" },
  { value: "590", label: "BL +590" },
  { value: "590", label: "MF +590" },
  { value: "1", label: "SX +1" },
  { value: "211", label: "SS +211" }
];




  // Sort the country codes array alphabetically by the label
  const sortedCountryCodes = countryCodes.sort((a, b) => a.label.localeCompare(b.label));

 
	 

	 const passworddanger=useRef(null)

	 const formref=useRef(null)

	const [confirmpassword,setconfirmpassword]=useState('')
	const [phonenumber,setPhonenumber]=useState('')
	const[zip,setZip]=useState("")
	 const [formData, setFormData] = useState({
    role:'user',
		first_name: "",
		last_name: ""
	 	,email: "",
		password:"",
		phone_number:"",
		

	});




  
  const goBack = () => {
    navigate(-1);
   
  };





  const handleSignup= async(e)=>{
e.preventDefault()
	
    const isValid = formref.current.checkValidity(); // Check if all form elements are valid

	
	
	

    if (isValid) {

		formData.phone_number=`${zip}${phonenumber}`
		console.log(confirmpassword)
		if(formData.password===confirmpassword){
			console.log(
				formData
			)
	
	
		
		
		try{
       
const signupData= await register(formData).unwrap()
console.log(signupData)
setFormData({
  role:"",
  first_name: "",
  last_name: ""
   ,email: "",
  password:"",
  phone_number:"",
  

})


if (signupData.status==="success"){

	const signinData= await login({"email":formData.email,'password':formData.password}).unwrap()
	console.log(signinData)
	if (signinData.is_number_verified===false){
	dispatch(setCredentials({access:signinData.access,refresh:signinData.refresh,user_id:signinData.user_id,is_number_verified:signinData.is_number_verified}))
	  navigate("/verify")
	}
	else{
		dispatch(setCredentials({access:signinData.access,refresh:signinData.refresh,user_id:signinData.user_id,is_number_verified:signinData.is_number_verified}))
	  navigate("/")
	}
  


}



		
		}
		catch(e){
			if (e.data.email){
				emaildanger.current.innerText="user with this email address already exists."
				
			}
			
		}
		 

		}
		else{
			passworddanger.current.innerText="The password confirmation does not match"
			

		}
	
			
     
    } else {
		formref.current.classList.add("was-validated")
	    }

       
    


  }

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevFormData) => ({ ...prevFormData, [name]: value }));
  };

  
  return (
    <div className=" fade show fade py-5" style={{diaplay:"block",backgroundColor:"#f0f0f0"}} id="signup-modal" tabindex="-1" aria-hidden="true">
    <div className="modal-dialog modal-lg modal-dialog-centered p-2 my-0 mx-auto" style={{maxWidth: "950px"}}>
      <div className="modal-content">
        <div className="modal-body px-0 py-2 py-sm-0">
        <buton  onClick={goBack} style={{cursor:"pointer"}} class=" position-absolute top-0 end-0 nav-link fs-sm py-1 px-2 mt-3 me-3" href="#" onclick="window.history.go(-1); return false;">
				<i class="fi-arrow-long-left fs-base me-2"></i>
				Go back
			</buton>          <div className="row mx-0 align-items-center">
            <div className="col-md-6 border-end-md p-4 p-sm-5">
              <h2 className="h3 mb-4 mb-sm-5">
              Join Short Stay Buddy.<br/>Get premium benefits:
              </h2>
              <ul className="list-unstyled mb-4 mb-sm-5">
                <li className="d-flex mb-2"><i className="fi-check-circle text-primary mt-1 me-2"></i><span>Add and promote your property</span></li>
                <li className="d-flex mb-2"><i className="fi-check-circle text-primary mt-1 me-2"></i><span>Easily manage your wishlist</span></li>
                <li className="d-flex mb-0"><i className="fi-check-circle text-primary mt-1 me-2"></i><span>Leave reviews</span></li>
              </ul><img className="d-block mx-auto" src={signuplogo} width="344" alt="Illustartion"/>
              <div className="mt-sm-4 pt-md-3">Already have an account? <Link to='/login' data-bs-toggle="modal" data-bs-dismiss="modal">Sign in</Link></div>
            </div>
            <div className="col-md-6 px-4 pt-2 pb-4 px-sm-5 pb-sm-5 pt-md-5">
              
              <form className="needs-validation" ref={formref}  novalidate>

              

                <div className="mb-4">
                  <label className="form-label  "   for="signup-name">First name</label>
                  <input       name="first_name"   value={formData.first_name} onChange={handleChange} className="form-control" type="text" placeholder="Enter your first name" required/>
                </div>

                <div className="mb-4">
                  <label className="form-label" for="signup-name">Other name(s)</label>
                  <input value={formData.last_name} name='last_name' onChange={handleChange} className="form-control" type="text" id="signup-name" placeholder="Enter your other name(s)" required/>
                </div>
                <div className="mb-4">
                  <label className="form-label" for="signup-email">Email address</label>
                  <input  value={formData.email} name='email' onChange={handleChange} className="form-control" type="email" id="signup-email" placeholder="Enter your email" required/>

				  <span class="text-danger" ref={emaildanger}></span>
                </div>
                <div class="mb-4">
							<label class="form-label" for="signup-phone">Phone Number</label>
							<div class="input-group form-group">
								<div class="col-5 text-muted">
								
									<select onChange={(e) => setZip(e.target.value)}  class="form-select" required  >
									<option  disabled hidden selected value=""> +</option>

			{
				sortedCountryCodes.map((countryCode,index) =>{
					return (
						<option key={index} value={countryCode.value}>{countryCode.label}</option>
					)
				})
			}

									</select>
								</div>

								<div class="col-7">
									<input name="phone_number"   onChange={(e) => setPhonenumber(e.target.value)} className="form-control" type="number"  placeholder="e.g. 240000000" minlength="9" min="0"  required/>
								</div>
							</div>
							<span class="text-danger" ref={phonedanger}></span>
													</div>

                <div className="mb-4">
                  <label className="form-label" for="signup-password">Password </label><br/>
				  <span className='fs-xs text-muted ' >min. 8 char & at least a special character</span>
                  <div className="password-toggle">
                    <input name="password"
        value={formData.password}
        onChange={handleChange} className="form-control " pattern=".*[\W_].*" type={seepassword?"text":"password"} id="signup-password" minlength="8" required/>
                    <label className="password-toggle-btn" aria-label="Show/hide password">
                      <input className="password-toggle-check" type="checkbox"/><span className="password-toggle-indicator" onClick={()=>{setseepassword(!seepassword)}}></span>
                    </label>
                  </div>
				  <span class="text-danger" ref={passworddanger}></span>
                </div>
                <div className="mb-4">
                  <label className="form-label" for="signup-password-confirm">Confirm password</label>
                  <div className="password-toggle">
                    <input className="form-control"   onFocus={()=>{passworddanger.current.innerText=""}}  onBlur={()=>{passworddanger.current.innerText=""}} onChange={(e) => setconfirmpassword(e.target.value)}   pattern=".*[\W_].*" type={seeconfirmpassword?"text":"password"} id="signup-password-confirm" minlength="8" required/>
                    <label className="password-toggle-btn" aria-label="Show/hide password">
                      <input name="confirmpassword" className="password-toggle-check" type="checkbox"/><span onClick={()=>{setconfirmseepassword(!seeconfirmpassword)}} className="password-toggle-indicator"></span>
                    </label>
                  </div>
                </div>
                <div className="form-check mb-4">
                  <input className="form-check-input" type="checkbox" id="agree-to-terms" required/>
                  <label className="form-check-label" for="agree-to-terms">By joining, I agree to the <Link  to='/terms-of-service'>Terms of use</Link> and <Link  to='/privacy-policy'>Privacy policy</Link></label>
                </div>


				{
					isloadingregister?<button type="button" class="btn btn-primary btn-lg rounded-pill w-100  ">
  <span class="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>
   registering ...
  
</button>: <button className="btn btn-primary btn-lg rounded-pill w-100 " onClick={handleSignup} >Sign up</button>

}

                <a className="btn my-3 btn-outline-info rounded-pill w-100 mb-3" href="#"><i className="fi-google fs-lg me-1"></i>Sign in with Google</a>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  )
}

export default SignupPage